<template>
  <v-app-bar
    id="app-bar"
    app
    :color="
      currentTheme.navDark && !$vuetify.theme.dark
        ? currentTheme.color
        : !$vuetify.theme.dark
        ? 'white'
        : ''
    "
    :dark="currentTheme.navDark || $vuetify.theme.dark"
    :clipped-left="currentTheme.navClippedLeft"
    :flat="false"
    elevate-on-scroll
  >
    <v-btn class="mr-3" icon @click="setDrawer(!drawer)">
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-menu
      </v-icon>
    </v-btn>

    <!-- <v-icon
        class="mx-4"
        large
      >
        mdi-vimeo
      </v-icon> -->
    <!-- <v-toolbar-title class="text-uppercase font-weight-black">{{$t('app.name')}}</v-toolbar-title> -->
    <base-wee-logo class="d-none d-lg-flex d-xl-flex" :image="true" />

    <!-- <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="
        $route.meta.pageTitle ? $t($route.meta.pageTitle.text): $route.name"
    /> -->
    <v-spacer />
    <v-text-field
      hide-details
      :placeholder="$t('base.search')"
      filled
      rounded
      dense
      prepend-inner-icon="mdi-magnify"
    />
    <v-spacer />

    <v-btn class="ml-2 d-none d-lg-flex d-xl-flex" min-width="0" icon to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <!-- Languge  -->
    <core-locale />
    <!-- End  -->

    <!-- Notification  -->
    <v-menu bottom left offset-y origin="top right">
      <template #activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" icon v-bind="attrs" v-on="on">
          <v-badge color="error" overlap>
            <template #badge>
              <span>0</span>
            </template>
            <v-icon>mdi-bell-outline</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <!-- <v-list
        dense
        class="py-0"
      >
        <v-list-item
          two-line
          v-for="(n, i) in notifications"
          :key="`notifications-${i}`"
          href="/"
        >
          <v-list-item-avatar>
            <v-img :src="'https://cdn.vuetifyjs.com/images/lists/1.jpg'"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Bekaku</v-list-item-title>
            <v-list-item-subtitle v-text="n" />
          </v-list-item-content>
        </v-list-item>

        <v-divider />
        <v-list-item
          class="mt-1"
          href="/"
        >
          <v-list-item-content class="text-center">
            <v-list-item-title class="primary--text">More</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
    </v-menu>
    <!-- End  -->

    <!-- User Avatar  -->
    <v-menu v-if="currentUser" offset-y left :close-on-content-click="true">
      <template #activator="{ on }">
        <v-btn icon class="mr-5" v-on="on">
          <v-avatar depressed size="36px">
            <img :src="currentUser.picture.xxx" :alt="currentUser.username">
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item router :to="`profile/${currentUser.username}`">
          <v-list-item-avatar size="50">
            <img :src="currentUser.picture.xx">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{
              `${currentUser.username}`
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              $t("nav.seeYourProfile")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item router @click="setDarkMode">
          <v-list-item-icon>
            <v-icon>{{
              darkMode ? "mdi-weather-night" : "mdi-lightbulb-outline"
            }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{
            darkMode ? $t("nav.darkMode") : $t("nav.lightMode")
          }}</v-list-item-title>
          <v-list-item-action class="pr-3">
            <v-switch v-model="darkMode" hide-details />
          </v-list-item-action>
        </v-list-item>

        <v-list-item router to="/profile/settings">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("base.setting") }}</v-list-item-title>
        </v-list-item>
        <v-list-item router @click="sigout">
          <v-list-item-icon>
            <v-icon>mdi-login-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("base.logout") }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <base-wee-loader v-model="loading" />
    </v-menu>
    <!-- End  -->

    <!-- <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/pages/user"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
import useSiteSetting from "@/composition/UseSiteSetting";
import useBase from "@/composition/UseBase";
import { defineComponent, ref } from "@vue/composition-api";
import AuthService from "@/api/AuthService";
export default defineComponent({
  name: "CoreAppBar",

  components: {
    CoreLocale: () => import("@/views/App/components/core/Locale")
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { refs, root }) {
    const authService = new AuthService();
    const {
      currentTheme,
      drawer,
      currentUser,
      setDrawer,
      setDarkMode,
      darkMode
    } = useSiteSetting(root);
    const { baseLogout } = useBase(refs, root);
    const loading = ref(false);

    const notifications = ref([
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one"
    ]);

    const sigout = async () => {
      loading.value = true;
      const { response } = await authService.userLogout();
      loading.value = false;
      if (response) {
        if (response.status) {
          baseLogout();
        }
      }
    };

    return {
      darkMode,
      currentTheme,
      drawer,
      currentUser,
      setDrawer,
      setDarkMode,
      notifications,
      sigout,
      loading
    };
  }
});
</script>
