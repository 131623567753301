var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"app-bar","app":"","color":_vm.currentTheme.navDark && !_vm.$vuetify.theme.dark
      ? _vm.currentTheme.color
      : !_vm.$vuetify.theme.dark
      ? 'white'
      : '',"dark":_vm.currentTheme.navDark || _vm.$vuetify.theme.dark,"clipped-left":_vm.currentTheme.navClippedLeft,"flat":false,"elevate-on-scroll":""}},[_c('v-btn',{staticClass:"mr-3",attrs:{"icon":""},on:{"click":function($event){return _vm.setDrawer(!_vm.drawer)}}},[(_vm.value)?_c('v-icon',[_vm._v(" mdi-view-quilt ")]):_c('v-icon',[_vm._v(" mdi-menu ")])],1),_c('base-wee-logo',{staticClass:"d-none d-lg-flex d-xl-flex",attrs:{"image":true}}),_c('v-spacer'),_c('v-text-field',{attrs:{"hide-details":"","placeholder":_vm.$t('base.search'),"filled":"","rounded":"","dense":"","prepend-inner-icon":"mdi-magnify"}}),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2 d-none d-lg-flex d-xl-flex",attrs:{"min-width":"0","icon":"","to":"/"}},[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('core-locale'),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var attrs = ref.attrs;
      var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"error","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v("0")])]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("mdi-bell-outline")])],1)],1)]}}])}),(_vm.currentUser)?_c('v-menu',{attrs:{"offset-y":"","left":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5",attrs:{"icon":""}},on),[_c('v-avatar',{attrs:{"depressed":"","size":"36px"}},[_c('img',{attrs:{"src":_vm.currentUser.picture.xxx,"alt":_vm.currentUser.username}})])],1)]}}],null,false,851513305)},[_c('v-list',[_c('v-list-item',{attrs:{"router":"","to":("profile/" + (_vm.currentUser.username))}},[_c('v-list-item-avatar',{attrs:{"size":"50"}},[_c('img',{attrs:{"src":_vm.currentUser.picture.xx}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(("" + (_vm.currentUser.username))))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("nav.seeYourProfile")))])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"router":""},on:{"click":_vm.setDarkMode}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.darkMode ? "mdi-weather-night" : "mdi-lightbulb-outline"))])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.darkMode ? _vm.$t("nav.darkMode") : _vm.$t("nav.lightMode")))]),_c('v-list-item-action',{staticClass:"pr-3"},[_c('v-switch',{attrs:{"hide-details":""},model:{value:(_vm.darkMode),callback:function ($$v) {_vm.darkMode=$$v},expression:"darkMode"}})],1)],1),_c('v-list-item',{attrs:{"router":"","to":"/profile/settings"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("base.setting")))])],1),_c('v-list-item',{attrs:{"router":""},on:{"click":_vm.sigout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-login-variant")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("base.logout")))])],1)],1),_c('base-wee-loader',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }